import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@mdi/font/css/materialdesignicons.min.css';
import './assets/styles/index.css'
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyCoQgZKagwZvTMZ8JFeBcsfKtzn4rF4z3I",
  authDomain: "impact-portal-reefos.firebaseapp.com",
  projectId: "impact-portal-reefos",
  storageBucket: "impact-portal-reefos.appspot.com",
  messagingSenderId: "538217049971",
  appId: "1:538217049971:web:30387082e50555130b1f06",
  measurementId: "G-CMRW2NMFSK"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const firebaseConfig_reefmap = {
  apiKey: "AIzaSyDByYxdnctmqQdd3VtjBV4NMAPe7O5_f64",
  authDomain: "cg-reefmap.firebaseapp.com",
  projectId: "cg-reefmap",
  storageBucket: "cg-reefmap.appspot.com",
  messagingSenderId: "761959520605",
  appId: "1:761959520605:web:d28c911b8a9d066aec30b4"
};

// Initialize Firebase
const app_reefmap = initializeApp(firebaseConfig_reefmap, "reefmap");
export const db_reefmap = getFirestore(app_reefmap);

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
