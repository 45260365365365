<template>
    <div class="card">
        <h1 class="title">CORALS</h1>
        <div class="chart-container">
            <canvas ref="coralChart"></canvas>
        </div>
        <div class="custom-legend">
            <span class="legend-item">
                <span class="legend-color" style="background-color: #0C508D;"></span>
                In nurseries
            </span>
            <span class="legend-item">
                <span class="legend-color" style="background-color: #27bdf4;"></span>
                In outplant sites
            </span>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export default {
    name: 'CoralCard',
    props: ['time_series'],
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        time_series() {
            this.createChart();
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeChart);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeChart);
        if (this.chart) {
            this.chart.destroy();
        }
    },
    methods: {
        createChart() {
            if (!this.time_series.length) {
                return;
            }
            const ctx = this.$refs.coralChart.getContext('2d');
            if (this.chart) {
                this.chart.destroy();
            }

            const years = [...new Set(this.time_series.map(item => item.year))].sort();

            const cumulativeData = years.map(year => {
                const dataUpToYear = this.time_series.filter(item => item.year <= year);
                const inNurseries = dataUpToYear.reduce((sum, item) => sum + item.in_nurseries, 0);
                const outplanted = dataUpToYear.reduce((sum, item) => sum + item.outplanted, 0);
                return { year, inNurseries, outplanted };
            });

            this.chart = new ChartJS(ctx, {
                type: 'bar',
                data: {
                    labels: cumulativeData.map(d => d.year),
                    datasets: [
                        {
                            label: 'In nurseries',
                            data: cumulativeData.map(d => d.inNurseries),
                            backgroundColor: '#0C508D',
                            barThickness: 15,
                        },
                        {
                            label: 'In outplant sites',
                            data: cumulativeData.map(d => d.outplanted),
                            backgroundColor: '#27bdf4',
                            barThickness: 15,
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                display: true,
                                borderDash: [4, 4],
                                color: 'rgba(255, 255, 255, 0.2)'
                            },
                            ticks: {
                                color: 'white',
                            }
                        },
                        y: {
                            stacked: true,
                            grid: {
                                display: true,
                                borderDash: [4, 4],
                                color: 'rgba(255, 255, 255, 0.2)'
                            },
                            ticks: {
                                color: 'white',
                            }
                        }
                    }
                }
            });
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        }
    }
}
</script>

<style scoped>
.card {
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 350px;
    /* Explicitly set the height */
}

.title {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.chart-container {
    flex-grow: 1;
    /* Allow the chart container to grow */
    position: relative;
    height: 100%;
    /* Explicitly set the height to ensure it grows with the card */
}

canvas {
    max-width: 100%;
    max-height: 230px;
    /* height: 100%; */
}

.custom-legend {
    display: flex;
    justify-content: flex-start;
    /* Left align the legend */
    margin-top: 10px;
    font-size: 12px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.legend-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}
</style>
