import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login.vue';
import Main from '@/components/Main.vue';
import { auth } from '@/main.js';
import { onAuthStateChanged } from 'firebase/auth';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/',
      name: 'Main',
      component: Main,
      meta: { requiresAuth: true },
    },
    { path: '*', redirect: '/login' }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (!requiresAuth) {
        next('/');
      } else {
        next();
      }
    } else {
      if (requiresAuth) {
        next('/login');
      } else {
        next();
      }
    }
  });
});


export default router;