<template>
    <div class="w-16 flex-none flex flex-col items-center bg-black pt-1">
        <button @click=""
            class="hover:bg-[#27bdf4] bg-neutral-700 relative flex justify-center items-center rounded m-1 transition-colors button-square">
            <span class="tooltip">Home</span>
            <span class="mdi mdi-home text-white text-lg"></span>
        </button>

        <!-- User Profile & Logout at the bottom -->
        <div class="mt-auto mb-4 flex flex-col items-center w-full">
            <button @click="logout"
                class=" bg-neutral-700 hover:bg-neutral-500 relative flex justify-center items-center rounded m-1 transition-colors button-square">
                <span class="mdi mdi-logout text-white text-lg"></span>
            </button>
        </div>
    </div>



</template>

<script>
import { getAuth, signOut } from 'firebase/auth';

export default {
    name: 'LeftSidebar',
    methods: {
        async logout() {
            const auth = getAuth();
            try {
                await signOut(auth);
            } catch (error) {
                console.error("Logout failed: ", error.message);
            }
        }

    }
}

</script>

<style scoped>
.button-square {
    width: 2.5rem;
    height: 2.5rem;
}

.button-square:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.tooltip {
    position: absolute;
    top: 50%;
    left: 110%;
    transform: translateY(-50%);
    background-color: rgba(57, 65, 80, 1.0);
    color: white;
    text-align: center;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.75rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0s linear 1s;
    z-index: 10;
}
</style>