<template>
    <div class="card">
        <div v-if="cameras.length > 0">
            <div class="header">
                <h3 class="title">REEFCAMS</h3>
                <Dropdown v-model="selectedCamera" :options="cameras" icon="mdi-chevron-down" class="z-50"/>
            </div>
            <div v-if="selectedCamera" class="stream-container">
                <iframe :src="streamUrl" frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import { db_reefmap, auth } from '../main'; 
import { collection, getDocs, query, where } from 'firebase/firestore';
import Dropdown from '@/components/utils/Dropdown.vue'; 

export default {
    name: 'CamCard',
    components: {
        Dropdown
    },
    data() {
        return {
            cameras: [],
            selectedCamera: null,
        };
    },
    mounted() {
        this.fetchCameras();
    },
    computed: {
        streamUrl() {
            return this.selectedCamera
                ? `https://camstreamer.com/embed/${this.selectedCamera.camstreamer_id}`
                : '';
        }
    },
    methods: {
        async fetchCameras() {
            try {
                const collectionRef = collection(db_reefmap, 'Cameras');
                const q = query(collectionRef);
                const querySnapshot = await getDocs(q);

                this.cameras = querySnapshot.docs
                .filter(doc => !doc.id.startsWith('debug'))
                    .map(doc => ({
                        id: doc.id,
                        name: doc.id,
                        camstreamer_id: doc.data().camstreamer_id,
                        location: doc.data().location
                    }));

                if (this.cameras.length > 0) {
                    this.selectedCamera = this.cameras[0];
                }
            } catch (error) {
                console.error("Error fetching cameras:", error);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style scoped>
.card {
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 400px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.title {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    float: left
    /* margin-bottom: 1rem; */
}
.stream-container {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 320px;
}
</style>