<template>
	<div class="main-container">
		<Header class="h-12" />
		<div class="main-content">
			<Sidebar v-if="!isMobile" />
			<div class="content-container">
				<div class="content-wrapper">
					<h3 class="title">Overview</h3>
					<div class="content">
						<div class="card-grid">
							<TotalsCard :global="global" />
							<SurvivalCard :global="global" :branches="branches" />
							<GenusCard :global="global"/>
							<CoralCard :time_series="time_series" />
							<MapCard :global="global" :branches="branches" />
							<BleachCard />
							<CamCard />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import TotalsCard from '@/components/TotalsCard.vue';
import SurvivalCard from '@/components/SurvivalCard.vue';
import GenusCard from '@/components/GenusCard.vue';
import CoralCard from '@/components/CoralCard.vue';
import BleachCard from '@/components/BleachCard.vue';
import MapCard from '@/components/MapCard.vue';
import CamCard from '@/components/CamCard.vue';

export default {
	name: 'Main',
	components: {
		Sidebar,
		Header,
		TotalsCard,
		SurvivalCard,
		GenusCard,
		CoralCard,
		BleachCard,
		MapCard,
		CamCard,
	},
	data() {
		return {
			global: [],
			time_series: [],
			branches: [],
		};
	},
	computed: {
		isMobile() {
			return window.innerWidth < 768;
		},
	},
	methods: {
		async fetchGlobal() {
			try {
				const response = await fetch('https://data.coralgardeners.org/data/global?org=coral-gardeners&pwd=showmethedata');
				const responseData = await response.json();
				this.global = responseData.global;

			} catch (error) {
				console.error('Error fetching global data:', error);
			}
		},
		async fetchBranches() {
			try {
				const response = await fetch('https://data.coralgardeners.org/data/branches?org=coral-gardeners&pwd=showmethedata');
				const responseData = await response.json();
				this.branches = responseData.branches;
			} catch (error) {
				console.error('Error fetching branches data:', error);
			}
		},
		async fetchTimeSeries() {
			try {
				const response = await fetch('https://data.coralgardeners.org/data/byyear?org=coral-gardeners&pwd=showmethedata');
				const responseData = await response.json();
				this.time_series = responseData.byyear;
			} catch (error) {
				console.error('Error fetching time series data:', error);
			}
		},
	},
	mounted() {
		this.fetchGlobal();
		this.fetchBranches();
		this.fetchTimeSeries();
	}
}
</script>

<style scoped>
.main-container {
	position: relative;
	height: 100vh;
	overflow: hidden;
}

.main-container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: black;
	z-index: -2;
}

.main-container::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('@/assets/images/background.png');
	background-size: cover;
	background-repeat: no-repeat;
	filter: blur(50px);
	z-index: -1;
}

.main-content {
	display: flex;
	height: calc(100vh - 3rem);
}

.content-container {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	/* 16:9 aspect ratio */
}

.content-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	z-index: 1;
	border-radius: 1rem;
	padding: 1rem;
	box-sizing: border-box;
	/* aspect-ratio: 16 / 9; */
	max-width: calc(100vh * (16 / 9));
	/* max-height: 100%; */
	margin: 0 auto;
}

.content {
	flex-grow: 1;
	overflow-y: auto;
	color: white;
	overflow: auto;
	scrollbar-width: none;
	/* Firefox */
	-ms-overflow-style: none;
}

.content::-webkit-scrollbar {
	display: none;
	width: 0;
	height: 0;
}

.title {
	color: white;
	font-size: 1.5rem;
	margin-bottom: 1rem;
	text-transform: uppercase;
	font-weight: bold;
	margin-left: 1rem;
}

.card-grid {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 1rem;
}

/* Top row: 3 cards, each 33% width */
.card-grid>*:nth-child(-n+3) {
	grid-column: span 2;
}

/* Middle row: CoralCard and MapCard, each 50% width */
.card-grid>*:nth-child(4),
.card-grid>*:nth-child(5) {
	grid-column: span 3;
}

/* Bottom row: BleachCard and CamCard, each 50% width */
.card-grid>*:nth-child(6),
.card-grid>*:nth-child(7) {
	grid-column: span 3;
}

@media (max-width: 767px) {
	.card-grid {
		grid-template-columns: 1fr;
	}

	.card-grid>* {
		grid-column: 1 !important;
	}
}
</style>
