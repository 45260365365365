<template>
    <div class="card">
        <h1 class="title">SURVIVAL</h1>
        <div class="content">
            <div class="chart-container">
                <canvas ref="chartRef"></canvas>
                <div class="survival-text">
                    {{ global_survival }}%
                    <div class="average-text">global</div>
                </div>
            </div>
            <div class="progress-card">
                <div class="progress-bars">
                    <div class="progress-bar-container" v-for="(branch, index) in branches" :key="index">
                        <div class="progress-title-container">
                            <p class="progress-title">{{ branch.Branch.split(',')[1] }}</p>
                            <span class="goal-number">{{ (branch['Fraction Alive'] * 100).toFixed(0) }}%</span>
                        </div>
                        <div class="progress-bar">
                            <div class="progress" :style="{ width: calculateProgress(branch) + '%' }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
    name: 'SurvivalCard',
    props: ['global', 'branches'],
    data() {
        return {
            global_survival: 0,
            chart: null,
        };
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
    },
    watch: {
        global() {
            this.global_survival = (this.global['Fraction Alive'] * 100).toFixed(0);
            this.createChart();
        }
    },
    methods: {
        createChart() {
            const ctx = this.$refs.chartRef.getContext('2d');
            this.chart = new ChartJS(ctx, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: [this.global_survival, 100 - this.global_survival],
                        backgroundColor: [
                            '#27bdf4',
                            '#333333'
                        ],
                        borderWidth: 0,
                        circumference: 360,
                        rotation: 0,
                        borderRadius: 2,
                    }]
                },
                options: {
                    responsive: true,
                    cutout: '80%',
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                }
            });
        },
        calculateProgress(branch) {
            const fractionAlive = branch['Fraction Alive'];
            return fractionAlive * 100;
        },
    },
};
</script>

<style scoped>
.card {
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 200px;
}

.title {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
}

.content {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* align-items: center; */
    height: 100%;
}

.chart-container,
.progress-card {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.chart-container {
    position: relative;
    height: 100%;
    /* padding: 20px; */
}

canvas {
    max-height: 70%;
}

.survival-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.average-text {
    font-size: 0.7rem;
    color: gray;
    margin-top: -0.4rem;
}

.progress-card {
    background-color: #1c1c1c;
    border-radius: 8px;
    padding: 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.progress-bars {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
}

.progress-bar-container {
    margin-bottom: 0.5rem;
}

.progress-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.2rem;
}

.progress-title {
    color: #ffffff;
    font-size: 10px;
    margin: 0;
}

.progress-bar {
    background-color: #0C508D;
    border-radius: 4px;
    height: 8px;
    position: relative;
}

.progress {
    height: 100%;
    background-color: #27bdf4;
    border-radius: 4px;
}

.goal-number {
    font-size: 0.8rem;
    color: #27bdf4;
}
</style>


