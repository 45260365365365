<template>
    <div class="card">
        <h1 class="large-number">{{ formattedNumber(total) }}</h1>
        <h3 class="title">Total Corals</h3>
        <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: progress + '%' }"></div>
            <span class="goal-number">{{ formattedNumber(goal) }}</span>
        </div>
        <div class="small-cards-container">
            <div class="small-card">
                <p class="small-card-title">In nurseries</p>
                <h2 class="small-card-number">{{ formattedNumber(nurseries) }}</h2>
            </div>
            <div class="small-card">
                <p class="small-card-title">In outplant sites</p>
                <h2 class="small-card-number">{{ formattedNumber(outplants) }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TotalsCard',
    props: ['global'],
    data() {
        return {
            goal: 300000,
        }
    },
    computed: {
        progress() {
            return (this.total / this.goal) * 100;
        },
        total() {
            return this.global['Fragments'] + this.global['Outplanted'];
        },
        nurseries() {
            return this.global['Fragments'];
        },
        outplants() {
            return this.global['Outplanted'];
        },
    },
    methods: {
        formattedNumber(number) {
            return new Intl.NumberFormat().format(number);
        }
    }
}
</script>

<style scoped>
.card {
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.large-number {
    font-size: 2rem;
    margin: 0;
    text-align: left;
    font-weight: bold;
}

.title {
    color: gray;
    text-align: left;
    margin-top: -5px;
}

.progress-bar-container {
    position: relative;
    width: 100%;
    background-color: #444;
    border-radius: 4px;
    height: 8px;
    margin: 1rem 0;
}

.progress-bar {
    height: 100%;
    background-color: #27bdf4;
    border-radius: 4px;
}

.goal-number {
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 0.7rem;
}

.small-cards-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.small-card {
    background-color: #333;
    border-radius: 4px;
    padding: 0.5rem;
    width: 47%;
}

.small-card-title {
    margin: 0;
    font-size: 0.8rem;
    color: gray;
    /* Titles in gray */
    text-align: left;
    /* Left aligned */
}

.small-card-number {
    margin: 0;
    font-size: 1.2rem;
    text-align: left;
    /* Left aligned */
}
</style>
