<template>
    <div class="card">
        <h1 class="title">DIVERSITY</h1>
        <div class="chart-container">
            <canvas ref="chartRef"></canvas>
            <div class="custom-legend">
                <div v-for="(genus, index) in topGenera" :key="genus.name" class="legend-item">
                    <span class="legend-color" :style="{ backgroundColor: colors[index % colors.length] }"></span>
                    {{ genus.name }}
                </div>
                <div class="legend-item" v-if="otherFraction > 0">
                    <span class="legend-color" :style="{ backgroundColor: colors[colors.length - 1] }"></span>
                    Other
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip);

export default {
    name: 'GenusCard',
    props: ['global'],
    data() {
        return {
            chart: null,
            colors: ['#27bdf4', '#1e95c2', '#166d8f', '#0e465d', '#06202b', '#333333'],
            otherFraction: 0,
        };
    },
    computed: {
        topGenera() {
            if (!this.global || this.global.length <= 0) return [];
            const genusFracs = this.global['Genus_Fracs'];
            const generaArray = Object.entries(genusFracs).map(([genus, fraction]) => ({ name: genus, fraction }));
            generaArray.sort((a, b) => b.fraction - a.fraction);

            const topGenera = generaArray.slice(0, 5);
            const otherGenera = generaArray.slice(5);
            this.otherFraction = otherGenera.reduce((sum, genus) => sum + genus.fraction, 0);

            return topGenera;
        }
    },
    watch: {
        global() {
            this.createChart();
        },
    },
    methods: {
        createChart() {
            const ctx = this.$refs.chartRef.getContext('2d');
            const data = [
                ...this.topGenera.map(genus => genus.fraction),
                this.otherFraction
            ];
            const labels = [
                ...this.topGenera.map(genus => genus.name),
                'Other'
            ];

            this.chart = new ChartJS(ctx, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: this.colors,
                        borderWidth: 0,
                    }]
                },
                options: {
                    responsive: true,
                    cutout: '50%',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.label || '';
                                    const value = context.raw || 0;
                                    const total = context.dataset.data.reduce((a, b) => a + b, 0);
                                    const percentage = ((value / total) * 100).toFixed(1);
                                    return `${label}: ${percentage}%`;
                                }
                            }
                        }
                    }
                }
            });
        }
    }
};
</script>

<style scoped>
.card {
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 200px;
}

.title {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
}

.chart-container {
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 75%;
}

canvas {
    max-width: 50%;
    max-height: 90%;
}

.custom-legend {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    font-size: 12px;
}

.legend-item {
    display: flex;
    align-items: center;
}

.legend-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}
</style>
